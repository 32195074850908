<!-- 应收管理 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20 clearfix">
      <!-- 客户名称 -->
      <FormItem>
        <span>客户名称：</span>
        <!-- <Select placeholder="请选择" v-model="customer_name" filterable clearable class="iviewIptWidth250">
          <Option v-for="(item, index) in thirdList" :value="item.customer_name" :key="index">{{ item.customer_name }}</Option>
        </Select> -->
        <Select placeholder="请选择" @on-change="changeList" v-model="customer_id" filterable clearable class="iviewIptWidth250">
          <Option v-for="(item, index) in thirdList" :value="item.customer_id" :key="index">{{ item.customer_name }}</Option>
        </Select>
      </FormItem>
      <FormItem class="po-create-number marginRight0" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query(true)">查询</span>
      </FormItem>
    </Form>
    <Table :productList="listColumns" :productData="listData" :pages="pages" :isLoad="isLoad" totalText="条记录" :total="total" @on-sort-change="sortChange" @change-page="changePage" :loading="isLoad">
      <div slot="summary" class="summary" v-if="listData && listData.length > 0">
        累计合计：<span class="color389">{{ $utils.formatMoney(total_surplus, true) }}</span>
      </div>
    </Table>
  </div>
</template>

<script>
import Table from '@/components/table'

export default {
  name: 'receivableManage',
  components: {
    Table,
  },
  computed: {
    totleMoney() {
      let totle = 0
      this.listData.forEach(item => {
        totle += item.money * item.num
      })
      return totle
    },
  },
  data() {
    return {
      customer_type: null,
      isLoad: true,
      total_surplus: 0,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 60,
        },
        {
          title: '客户名称',
          key: 'name',
          align: 'center',
          minWidth: 400,
        },
        {
          title: '累计应收(元)',
          align: 'center',
          maxWidth: 370,
          key: 'should_receivable',
          sortable: 'custom',
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.should_receivable, true))
          },
        },
        {
          title: '累计已收(元)',
          align: 'center',
          maxWidth: 370,
          key: 'already_receivable',
          sortable: 'custom',
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.already_receivable, true))
          },
        },
        {
          title: '剩余应收(元)',
          align: 'center',
          maxWidth: 370,
          key: 'surplus_receivable',
          sortable: 'custom',
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.surplus_receivable, true))
          },
        },
      ],
      // 表单下拉选项
      thirdList: [],
      // 查询参数
      customer_name: '',
      customer_id: '',
      pages: {
        page: 1,
        rows: 10,
      },
      total: 1,
      step: {}
    }
  },
  mounted() {
    this.query()
    this.getThirdList()
  },
  methods: {
    sortChange(column) {
				this.step.sort_field = column.key;
      	this.step.sort_type = column.order;
      	this.query();
    },
    changeList(e) {
      if (e) {
        for (let j = 0; j < this.thirdList.length; j++) {
          if (this.thirdList[j].customer_id === e) {
            this.customer_type = this.thirdList[j].customer_type
            break
          }
        }
      }
    },
    changePage(e) {
      this.pages.page = e
      this.query()
    },
    // 下拉
    getThirdList() {
      this.$http.get(this.$api.customerList).then(res => {
        this.thirdList = res.data
      })
    },
    // 查询
    query(foo = false) {
      let data = {
        page: this.pages.page,
        rows: this.pages.rows,
      }
      if (foo) {
        this.pages.page = 1
        data.page = 1
      }
      // if (this.customer_name) {
      //   data.customer_name = this.customer_name
      // }
      if (this.customer_id) {
        data.customer_id = this.customer_id
        data.customer_type = this.customer_type
      }
      this.isLoad = true
      Object.assign(data, this.step)
      this.$http.get(this.$api.receivable, data, true).then(res => {
        this.isLoad = false
        this.listData = res.data.result
        this.total_surplus = res.data.total_surplus
        this.total = res.data.total_record
      })
    },
  },
}
</script>

<style scoped lang="less">
.pagePadding {
  .marginLeft20 {
    width: 100px;
  }
}
</style>
